<template>
  <!--    User Form-->
  <validation-observer
    ref="userForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateUserMode?'Update':'Create'} User`"
      :show="userModalOpened"
      :show-overlay="deletingUser"
      size="md"
      @onClose="closeUserModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedUserId"
            placeholder="All Users"
            :options="users"
            label-name="name"
            value-name="id"
            name="users"
            @input="populateUserForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
            name="delete_user"
          >
            <b-dropdown-item
              id="cd_user"
              @click="deleteUser()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            name="user_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetUserForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="user.first_name"
        name="first_name"
        placeholder="Enter user first name"
        rules="required"
        label="First Name"
      />
      <lenon-input
        v-model="user.last_name"
        name="last_name"
        placeholder="Enter user last name"
        rules="required"
        label="Last Name"
      />
      <lenon-input
        v-model="user.email"
        name="email"
        placeholder="Enter user email"
        rules="required|email"
        label="Email"
      />
      <lenon-input
        v-model="user.phone"
        name="phone"
        placeholder="Enter user phone"
        label="Phone"
        rules="required"
      />
      <div class="mt-2">
        <lenon-select
          v-model="user.role_id"
          name="role_id"
          value-name="id"
          label-name="name"
          placeholder="Select Role"
          label="Role"
          :options="roles"
          rules="required"
        />
      </div>
      <div class="mt-2">
        <lenon-select
            v-model="user.user_type"
            name="user_type"
            value-name="id"
            label-name="name"
            placeholder="Select User Type"
            label="User Type"
            :options="userTypes"
            rules="required"
        />
      </div>
<!--      <b-row>-->
<!--        <div class="col-12">-->
<!--          <b-form-checkbox-->
<!--            id="is_teacher"-->
<!--            v-model="user.teacher"-->
<!--            class="mb-1"-->
<!--          >-->
<!--            Teacher-->
<!--          </b-form-checkbox>-->
<!--        </div>-->
<!--      </b-row>-->
      <b-row v-if="user.teacher">
        <div class="col-12 mb-1">
          <strong>You may add subjects this teacher teaches</strong>
        </div>
      </b-row>
      <div v-if="user.teacher">
        <b-row
          v-for="(ts, index) in user.teacher_subjects"
          :key="index"
          class="mb-1"
          style="border-bottom: 1px #c4c0c0 dashed;"
        >
          <div class="col-md-12 mb-1">
            <lenon-multi-select
              v-model="ts.subjects"
              name="subject"
              value-name="id"
              label-name="name"
              placeholder="Select Subjects"
              label="Subjects"
              :options="subjects"
              rules="required"
            />
          </div>
          <div class="col-md-10">
            <lenon-select
              v-model="ts.class_id"
              name="class_id"
              value-name="id"
              label-name="name"
              placeholder="Select Class"
              label="Class"
              :options="classes"
              rules="required"
            />
          </div>
          <div
            class="col-md-2"
            :style="$store.getters['app/isLargeScreen']? 'margin-top: 25px;':''"
          >
            <lenon-dropdown
              icon="TrashIcon"
            >
              <b-dropdown-item
                @click="removeTeacherSubject(index)"
              >
                Yes
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                No
              </b-dropdown-item>
            </lenon-dropdown>
          </div>
          <div class="col-12 mb-1">
            <lenon-multi-select
              v-model="ts.abilities"
              name="subject"
              value-name="id"
              label-name="name"
              placeholder="Select Abilities"
              label="Mobile App Abilities"
              :options="abilities"
              rules="required"
            />
          </div>
        </b-row>
        <b-row>
          <div class="col-12">
            <lenon-button
              name="add_teacher"
              icon="PlusIcon"
              :icon-only="true"
              class="float-right"
              variant="outline-primary"
              @onClick="addTeacherSubject()"
            />
          </div>
        </b-row>
      </div>

      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeUserModal()"
          />
          <lenon-button
            name="save_user"
            :label="updateUserMode?'Update':'Create'"
            :disabled="invalid"
            :loading="userLoading"
            loading-text="Loading..."
            @onClick="updateUserMode?updateUser():createUser()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import {
  CREATE_USER_M,
  DELETE_TEACHER_SUBJECT_M,
  DELETE_USER_M,
  UPDATE_USER_M,
} from '@/graphql/mutations'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

export default {
  name: 'UserSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      userModalOpened: false,
      updateUserMode: false,
      userLoading: false,
      deletingUser: false,
      selectedUserId: null,
      abilities: [
        {
          id: 'register-student',
          name: 'Register Student',
        },
        {
          id: 'update-student',
          name: 'Update Student',
        },
        {
          id: 'record-attendance',
          name: 'Record Attendance',
        },
        {
          id: 'manage-assessments',
          name: 'Manage Assessments',
        },
        {
          id: 'access-recurring-fees',
          name: 'Access Recurring Fees',
        },
        {
          id: 'view-arrears-list',
          name: 'View Arrears List',
        },
        {
          id: 'manage-student-promotion',
          name: 'Manage Student Promotion',
        },
        {
          id: 'view-timetable',
          name: 'View Time Table',
        },
        {
          id: 'access-bus-fees',
          name: 'Access Bus Fees',
        },
      ],
      userTypes:[
        {id: "user",name:"User"},
        {id: "teacher",name:"Teacher"},
        {id: "driver",name:"Driver"}
      ],
      user: {
        id: null,
        first_name: null,
        last_name: null,
        teacher: false,
        email: null,
        phone: null,
        role_id: null,
        user_type: null,
        teacher_subjects: [{
          id: null,
          class_id: null,
          subjects: [],
          abilities: [],
        }],
      },
    }
  },
  computed: {
    selectedUser() {
      const user = this.users.find(ter => ter.id === this.selectedUserId)
      if (!user) {
        return null
      }
      return {
        ...user,
        teacher_subjects: user.teacher_subjects.map(
          ts => ({
            ...ts,
            subjects: ts.subjects.map(
              s => this.subjects.find(os => +os.id === +s),
            ),
            abilities: ts.abilities.map(
              s => this.abilities.find(os => os.id === s),
            ),
          }
          ),
        ),
      }
    },
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
    users() {
      return this.$store.getters['accountUsers/users']
        .map(u => ({
          ...u,
          name: `${u.first_name} ${u.last_name}`,
        }))
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    userType(){
      return this.user.user_type
    }
  },
  watch: {
    userType(val){
      this.user.teacher = val === 'teacher';
    },
    modalOpened(opened) {
      this.userModalOpened = opened
    },
  },
  methods: {
    addTeacherSubject() {
      this.user.teacher_subjects.push({
        id: null,
        class_id: null,
        subjects: [],
        abilities: [],
      })
    },
    removeTeacherSubject(index) {
      const ts = this.user.teacher_subjects[index]
      if (ts.id) {
        this.$apollo.mutate({
          mutation: DELETE_TEACHER_SUBJECT_M,
          variables: { id: ts.id },
        })
          .then(res => {
            this.userLoading = false
            this.user.teacher_subjects.splice(index, 1)
            this.showSuccess('Deleted Successfully')
            this.$store.commit('accountUsers/removeTeacherSubject',
              {
                id: this.user.id,
                teacherSubId: ts.id,
              })
          })
          .catch(err => {
            console.log(err)
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.user.teacher_subjects.splice(index, 1)
      }
    },
    // user
    resetUserForm() {
      this.user = {
        id: null,
        first_name: null,
        last_name: null,
        teacher: false,
        email: null,
        phone: null,
        role_id: null,
        user_type: null,
        teacher_subjects: [{
          id: null,
          class_id: null,
          subjects: [],
          abilities: [],
        }],
      }
      this.selectedUserId = null
      this.updateUserMode = false
      this.$refs.userForm.reset()
    },
    populateUserForm(user) {
      if (this.selectedUser) {
        this.updateUserMode = true
        this.user = {
          id: this.selectedUser.id,
          first_name: this.selectedUser.first_name,
          last_name: this.selectedUser.last_name,
          email: this.selectedUser.email,
          teacher: this.selectedUser.teacher,
          phone: this.selectedUser.phone,
          user_type: this.selectedUser.user_type,
          role_id: this.roles.find(r => r.name === this.selectedUser.role)?.id || null,
          teacher_subjects: this.selectedUser?.teacher_subjects,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.user.__typename
      } else {
        this.resetUserForm()
      }
    },
    closeUserModal() {
      this.userModalOpened = false
      this.$emit('modalClosed')
    },
    updateUser() {
      this.error = {}
      if (!this.user.id) {
        return
      }
      this.userLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_USER_M,
        variables: {
          input: {
            ...this.user,
            teacher_subjects: this.user.teacher_subjects.map(t => ({
              ...t,
              subjects: t.subjects.map(s => s.id),
              abilities: t.abilities.map(s => s.id),
            })),
          },
        },
      })
        .then(res => {
          this.showSuccess('Updated user successfully')
          this.userLoading = false
          this.$store.commit('accountUsers/updateUser', res.data.updateUser)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update user')
          this.userLoading = false
        })
    },
    createUser() {
      this.error = {}
      this.userLoading = true
      this.$apollo.mutate({
        mutation: CREATE_USER_M,
        variables: {
          input: {
            ...this.user,
            teacher_subjects: this.user.teacher ? this.user.teacher_subjects.map(t => ({
              ...t,
              subjects: t.subjects.map(s => s.id),
              abilities: t.abilities.map(s => s.id),
            })) : [],
          },
        },
      })
        .then(res => {
          this.showSuccess('Created user successfully')
          this.userLoading = false
          this.$store.commit('accountUsers/addUser', res.data.createUser)
          this.resetUserForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create user')
          this.userLoading = false
        })
    },
    deleteUser() {
      if (!this.selectedUserId) {
        this.showInfo('Please select a user')
        return
      }
      this.deletingUser = true
      this.$apollo.mutate({
        mutation: DELETE_USER_M,
        variables: { id: this.selectedUserId },
      })
        .then(() => {
          this.deletingUser = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('accountUsers/removeUser', this.selectedUserId)
          this.selectedUserId = null
          this.resetUserForm()
        })
        .catch(() => {
          this.showError('Failed to delete user')
          this.deletingUser = false
        })
    },
  }
  ,
}
</script>
